<template>
  <div style="cursor:pointer">
    <!-- 头部组件 -->
    <header_public></header_public>
    <div class="maska" v-if="videoState == true" @click="masksCloseFun()"></div>
    <div class="videomasks" v-if="videoState == true">
      <video
        :src="videoSrc"
        controls="controls"
        autoplay
        style="width: 57vw;height:32vw;"
      >
        您的浏览器不支持 video 标签。
      </video>
    </div>
    <div v-else-if="imgState">
      <div class="maska" @click="masksCloseFun()"></div>
      <div class="imagemasks">
        <img :src="imgSrc" controls="controls" autoplay class="show-img" />
      </div>
    </div>
    <!-- 背景图 -->
    <div class="cloudtop">
      <div class="cloudtopword">
        <p class="cloudp1">前端云计算</p>
        <p class="cloudp2">
          选择前端云计算就是选择了互联网的未来!
          <br />崛起时间短,发展迅速，前景远大！ <br />就业门路广，就业待遇好！
        </p>
        <!-- 播放 -->
        <img
          src="../../assets/images/banner-bf.png"
          alt
          @click="checkVideoFun(majorvideo[0] ? majorvideo[0]['image'] : '')"
        />
      </div>
    </div>
    <!-- 为什么首选前端云计算 -->
    <div class="garybg">
      <p class="p1">学IT为什么首选前端云计算</p>
      <div class="study">
        <dl>
          <dt>
            <img src="../../assets/images/h3-icon-with-text-img-1.png" alt />
          </dt>
          <dd class="dd1">全局思维沟通成本低</dd>
          <dd class="dd2">多样编码技能</dd>
          <dd class="dd2">全局思维能力</dd>
          <dd class="dd2">1+1＞2</dd>
        </dl>
        <dl>
          <dt>
            <img src="../../assets/images/jiantou2.png" alt />
          </dt>
          <dd class="dd1">多元化工作机会</dd>
          <dd>APP开发工程师</dd>
          <dd>小游戏开发工程师</dd>
          <dd>全栈工程师</dd>
          <dd>小程序开发工程师</dd>
        </dl>
        <dl>
          <dt>
            <img src="../../assets/images/share1.png" alt />
          </dt>
          <dd class="dd1">丰厚薪酬晋升空间大</dd>
          <dd>北京 ¥24310</dd>
          <dd>上海 ¥20270</dd>
          <dd>深圳 ¥19990</dd>
          <dd>天津 ¥16420</dd>
        </dl>
      </div>
      <!-- 2020V5.0课程 -->
      <div class="course">
        <img
          style="width:23.69vw;height:36.6vw"
          src="../../assets/images/phone.png"
          alt
        />
        <div class="courseword">
          <P class="coursep">
            2020 V5.0课
            <br />程体系
            <span style="color:red">强势来袭</span>
          </P>
          <ul>
            <li>知识数量以及案例数量增加、项目数量涉及广泛、新增知识点</li>
            <li>5.0课程体系：</li>
            <li>1. 新增linux技术</li>
            <li>2. 新增Django 后台技术</li>
            <li>3. 新增企业级项目实战</li>
            <li>4. 新增云计算技术</li>
            <li>5. 新增项目云管理</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 分段课程体系 -->
    <div class="course" style="margin-top:10vw">
      <div class="courseword">
        <P class="coursep">
          分段课程体系
          <br />
          <span style="color:red">循序渐进</span>学习
        </P>
        <ul>
          <li>✔ 第一阶段 PC+移动、响应式web开发</li>
          <li>✔ 第二阶段 JavaScript核心编程</li>
          <li>✔ 第三阶段 全栈开发</li>
          <li>✔ 第四阶段 云计算开发</li>
          <li>✔ 第五阶段 企业项目开发实战</li>
          <li>✔ 第六阶段 企业级核心技术实战</li>
        </ul>
      </div>
      <img
        style="width:40.46vw;height:28vw;margin-top:3vw;"
        src="../../assets/images/h3-card-blue-img.png"
        alt
      />
    </div>
    <!-- 核心知识 -->
    <div class="center">
      <ul>
        <li class="li1">核心知识</li>
        <li class="li2" style="font-size:1.12rem;font-weight:normal;">
          HTML5,CSS3,FLEX,sass,less,stylus, 移动端,媒体查询,响应式,BootStrap,
          js,AJAX,Node,Express,Vue,微信小程序,
          linux,docker,Python,Django,云服务器管理
        </li>
      </ul>
      <ul>
        <li class="li1">实战项目</li>
        <li class="li2" style="font-size:1.12rem;font-weight:normal;">
          买买商城,饿了么外卖,听听音乐播放,
          每每在线教育,每每在线教育后台管理系统,
          博客开发,B2C电商后台管理统统,生鲜水果购物商城 云服务器实战
        </li>
      </ul>
      <ul>
        <li class="li1">能力目标</li>
        <li class="li2" style="font-size:1.12rem;font-weight:normal;">
          熟练掌握H5,C3,js,jq,ajax等前端基本知识,
          项目实战操作前端主流框架Vue,React,微信小程序 熟练掌握linux系统管理
          熟练掌握项目部署 熟练掌握云技术,云管理,增加就业门路
        </li>
      </ul>
    </div>
    <!-- 前景介绍 -->
    <div class="garybg">
      <!-- <p class="pp" style="padding-top:4vw">我们的团队</p> -->
      <p class="p2" style="padding-top:3vw">前景介绍</p>
      <img
        style="margin:0 auto;margin-top:1vw;"
        src="../../assets/images/btzst.png"
        alt
      />
      <p class="p3">
        近几年IT巨头纷纷创建了”云”,阿里云,百度云,腾讯云等,我们专业致力于通过前端技术将公司需求转为前端界面,再通过云技术将前端界面呈现到每个人的手机上,电脑上.我们学员就业岗位涵盖:前端开发,全栈开发,web研发,H5游戏开发,微信公众号小程序开发,前端架构师,云计算开发,运维,测试等岗位,就业面广,待遇好!选择前端云计算就是选择了互联网的未来!
      </p>
      <div
        class="futurevideo"
        @click="checkVideoFun(majorvideo[0] ? majorvideo[0]['image'] : '')"
      >
        <img
          class="videoimg"
          src="../../assets/images/video-text-button.png"
          alt
        />
        <img class="ladyimg" src="../../assets/images/lady.png" alt />
      </div>
    </div>
    <!-- 课程体系优势 -->
    <div class="advantage">
      <img
        style="width:33vw;margin-top:5vw"
        src="../../assets/images/home-3-inner-rev-3-img-2.png"
        alt
      />
      <div>
        <!--        <p class="pp">我们的团队</p>-->
        <p class="p2">5.0课程体系优势</p>
        <img
          style="margin:0 auto;margin-top:1vw;"
          src="../../assets/images/btzst.png"
          alt
        />
        <div class="advantagebox">
          <div class="advantageflex">
            <img src="../../assets/images/gxdg.png" alt />
            <ul>
              <li class="li1" style="margin-left:0.8vw">优势一零基础入门</li>
              <li class="li2" style="margin-left:0.8vw;margin-top:0.4vw">
                课程精心研发,零基础也能入门学习,
                <br />小白也有机会成为Web前端工程师。
              </li>
            </ul>
          </div>
          <div class="advantageflex">
            <img src="../../assets/images/gxdg.png" alt />
            <ul>
              <li class="li1" style="margin-left:0.8vw">优势二课程升级</li>
              <li class="li2" style="margin-left:0.8vw;margin-top:0.4vw">
                结合当下互联网大趋势,招聘需求数据统计,全栈课程体系升级为中级全栈工程师课程。
              </li>
            </ul>
          </div>
        </div>
        <div class="advantagebox">
          <div class="advantageflex">
            <img src="../../assets/images/gxdg.png" alt />
            <ul>
              <li class="li1" style="margin-left:0.8vw">优势三实时更新</li>
              <li class="li2" style="margin-left:0.8vw;margin-top:0.4vw">
                每月小版本更新迭代 ,每季大版本升级。
              </li>
            </ul>
          </div>
          <div class="advantageflex">
            <img src="../../assets/images/gxdg.png" alt />
            <ul>
              <li class="li1" style="margin-left:0.8vw">优势四项目实战</li>
              <li class="li2" style="margin-left:0.8vw;margin-top:0.4vw">
                项目实战全程贯穿课程始末,让你提前接触企业级真实大项目,紧贴企业实际需求。
              </li>
            </ul>
          </div>
        </div>
        <div class="advantagebox">
          <div class="advantageflex">
            <img src="../../assets/images/gxdg.png" alt />
            <ul>
              <li class="li1" style="margin-left:0.8vw">优势五讲练结合</li>
              <li class="li2" style="margin-left:0.8vw;margin-top:0.4vw">
                理论实践,讲练结合,熟理论,懂应用。
              </li>
            </ul>
          </div>
          <div class="advantageflex">
            <img src alt />
            <ul>
              <li class="li1"></li>
              <li class="li2"></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- <p class="pp" style="margin-top:10vw;">专业素养、行业精英</p> -->
    <p class="p2" style="font-size：36px;margin-top:10vw;">骨干讲师介绍</p>
    <img
      style="margin:0 auto;margin-top:1vw;margin-bottom:2vw"
      src="../../assets/images/btzst.png"
      alt
    />

    <!-- 教师团队轮播 -->
    <div class="swiper_con con_style">
      <!-- 轮播图 -->
      <div class="bannerWrap">
        <!--style="width:100%;height:300px;" -->
        <!-- Swiper -->
        <div
          class="swiper-container teacher-con"
          @mousemove="stop(swiperTracher)"
          @mouseleave="start(swiperTracher)"
        >
          <div class="swiper-wrapper">
            <div
              class="swiper-slide teachers_box"
              v-for="(teacher, index) in teacherlist"
              :key="index"
            >
              <a href="javascript:void(0)" class="enlarge">
                <img
                  :src="teacher.imageThumb ? teacher.imageThumb : teacher.image"
                  alt
                />
              </a>
              <div class="teacher_team_con">
                <!-- <div class="team_con_top flex_style direction_row flex_nowrap justify_end align_center">
                                    <span class="text_family">大数据专业</span>
                                    <img src="../../assets/images/quanping.png" alt="">
                </div>-->
                <div class="team_con_bottom text_left">
                  <p class="text_family">
                    {{ teacher.title ? teacher.title : "" }}
                  </p>
                  <span class="text_family">{{
                    teacher.extend ? teacher.extend.subtitle : ""
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- Add Pagination -->
          <div
            class="swiper-pagination swiper-pagination-white major-spit teach-spit"
            id="swiper-spit"
          ></div>
        </div>
      </div>
      <!-- Add Arrows -->
      <div
        class="swiper-button-next swiper-button-white teachers-switch-next"
      ></div>
      <div
        class="swiper-button-prev swiper-button-white teachers-switch-prev"
      ></div>
    </div>
    <!-- 新闻与更新 -->

    <!-- 面向启动 -->
    <!-- <p class="pp" style="margin-top:7vw">我们的工作流程</p> -->
    <p class="p2" style="font-size：2.25rem;margin-top:7vw">数据科学解决方案</p>
    <img
      style="margin:0 auto;margin-top:1vw;"
      src="../../assets/images/btzst.png"
      alt
    />
    <p style="margin-top:4vw;margin-bottom:8vw;">
      <img
        style="width:62.5vw;height:15.67vw"
        src="../../assets/images/cloudxuxian.png"
        alt
      />
    </p>
    <!-- 学员作品 -->
    <div class="garybg">
      <!-- <p class="pp" style="padding-top:7vw">我们的工作流程</p> -->
      <p class="p2" style="font-size：2.25rem;padding-top:7vw">学员作品</p>
      <img
        style="margin:0 auto;margin-top:1vw;"
        src="../../assets/images/btzst.png"
        alt
      />
      <div class="workflex" style="cursor:pointer">
        <img
          :src="projectlist[0] ? projectlist[0]['imageThumb'] : ''"
          alt
          @click="checkimgFun(projectlist[0] ? projectlist[0]['image'] : '')"
        />
        <img
          :src="projectlist[1] ? projectlist[1]['imageThumb'] : ''"
          alt
          @click="checkimgFun(projectlist[1] ? projectlist[1]['image'] : '')"
        />
        <img
          :src="projectlist[2] ? projectlist[2]['imageThumb'] : ''"
          alt
          @click="checkimgFun(projectlist[2] ? projectlist[2]['image'] : '')"
        />
      </div>
      <div class="workflex" style="cursor:pointer">
        <img
          :src="projectlist[3] ? projectlist[3]['imageThumb'] : ''"
          alt
          @click="checkimgFun(projectlist[3] ? projectlist[3]['image'] : '')"
        />
        <img
          :src="projectlist[4] ? projectlist[4]['imageThumb'] : ''"
          alt
          @click="checkimgFun(projectlist[4] ? projectlist[4]['image'] : '')"
        />
        <img
          :src="projectlist[5] ? projectlist[5]['imageThumb'] : ''"
          alt
          @click="checkimgFun(projectlist[5] ? projectlist[5]['image'] : '')"
        />
      </div>
    </div>
    <!-- 前端云计算就业方向 -->
    <!-- <p class="pp1">技术领域</p> -->
    <!-- <p class="p2" style="font-size：2.25rem">前端云计算</p> -->
    <!--    <p class="pp1">技术领域</p>-->
    <!--    <p class="p2" style="font-size：2.25rem">前端云计算</p>-->
    <p class="p2" style="font-size：2.75rem">就业方向</p>
    <img
      style="margin:0 auto;margin-top:1vw;margin-bottom:2vw;"
      src="../../assets/images/btzst.png"
      alt
    />
    <div class="direction">
      <ul>
        <li>
          <img
            style="width:14.84vw;height:13.2vw"
            src="../../assets/images/yun1.png"
            alt
          />
        </li>
        <li class="li1">前端开发工程师</li>
        <li class="li2">平均薪资：12710元</li>
      </ul>
      <ul>
        <li>
          <img
            style="width:14.84vw;height:13.2vw"
            src="../../assets/images/red.png"
            alt
          />
        </li>
        <li class="li1">H5游戏开发</li>
        <li class="li2">平均薪资：17790元</li>
      </ul>
      <ul>
        <li>
          <img
            style="width:14.84vw;height:13.2vw"
            src="../../assets/images/yun3.png"
            alt
          />
        </li>
        <li class="li1">云计算开发</li>
        <li class="li2">平均薪资：15820元</li>
      </ul>
    </div>
    <div class="direction">
      <ul>
        <li>
          <img
            style="width:14.84vw;height:13.2vw"
            src="../../assets/images/yun4.png"
            alt
          />
        </li>
        <li class="li1">前端架构师</li>
        <li class="li2">平均薪资：20590元</li>
      </ul>
      <ul>
        <li>
          <img
            style="width:14.84vw;height:13.2vw"
            src="../../assets/images/yun5.png"
            alt
          />
        </li>
        <li class="li1">微信小游戏开发</li>
        <li class="li2">平均薪资：12280元</li>
      </ul>
      <ul>
        <li>
          <img
            style="width:14.84vw;height:13.2vw"
            src="../../assets/images/yun6.png"
            alt
          />
        </li>
        <li class="li1">全栈工程师</li>
        <li class="li2">平均薪资：17210元</li>
      </ul>
    </div>
    <div class="direction">
      <ul>
        <li>
          <img
            style="width:14.84vw;height:13.2vw"
            src="../../assets/images/yun7.png"
            alt
          />
        </li>
        <li class="li1">HTML5游戏开发</li>
        <li class="li2">平均薪资：17790元</li>
      </ul>
      <ul>
        <li>
          <img
            style="width:14.84vw;height:13.2vw"
            src="../../assets/images/yun8.png"
            alt
          />
        </li>
        <li class="li1">web研发工程师</li>
        <li class="li2">平均薪资：13730元</li>
      </ul>
      <ul>
        <li>
          <img
            style="width:14.84vw;height:13.2vw"
            src="../../assets/images/yun9.png"
            alt
          />
        </li>
        <li class="li1">运维开发工程师</li>
        <li class="li2">平均薪资：15390元</li>
      </ul>
    </div>
    <div class="garybg">
      <div class="job">
        <div class="jobword">
          <!-- <p class="workp">新闻与更新</p> -->
          <p class="workp2">就业辅导</p>
          <img
            style="margin-top:1vw;margin-right:27vw;"
            src="../../assets/images/btzst.png"
            alt
          />
          <ul>
            <li>
              <span style="color:#14134F">■</span
              >对多模拟面试，学员模拟多次，为找工作做充分准备
            </li>
            <li>
              <span style="color:#14134F">■</span
              >7*24在线服务答疑，帮助学员解决工作中遇到的问题，
              <br />保证安全度过试用期
            </li>
            <li>
              <span style="color:#14134F">■</span
              >一对一面试复盘，找到问题所在，帮助学员找到满意的工作
            </li>
          </ul>
        </div>
        <img
          class="img1"
          src="../../assets/images/home-3-inner-rev-1-img-1.png"
          alt
        />
      </div>
      <recollections_publc major="前端云计算"></recollections_publc>
    </div>
    <!-- 底部 -->
    <bottom_public></bottom_public>
  </div>
</template>
<script>
import Swiper from "swiper/js/swiper.min.js";
import "swiper/css/swiper.min.css";
import bottom_public from "../../components/bottom_public/bottom_public";
import header_public from "../../components/header_public/header_public";
import recollections_publc from "../../components/recollections_publc/recollections_publc";

const axios = require("axios").default;
export default {
  metaInfo: {
    title: "前端云计算培训|WEB前端培训|HTML5培训-积云教育",
    meta: [
      {
        name: "description",
        content:
          "积云教育前端云计算课程包含了HTML5,CSS3,FLEX,sass,less,stylus, 移动端等内容,拥有最完整的前端云计算课程培训体系,提供更加权威的前端云计算培训课程。",
      },
      {
        name: "keywords",
        content: "前端云计算培训,前端培训,前端开发培训,HTML5培训,WEB前端培训",
      },
    ],
  },
  created() {
    this.getteacherlist(); //获取教师团队列表
    this.getprojectlist(); //获取学员列表
    this.getmajorvideo(); //获取专业宣传视频
  },
  data() {
    return {
      teacherlist: {},
      projectlist: {},
      videoSrc: "",
      videoState: false,
      imgSrc: "",
      imgState: false,
      majorvideo: "",
      swiperTracher: null,
    };
  },
  methods: {
    //获取学员作品
    getprojectlist() {
      axios
        .get(
          "/api/block/getItemsByName?name=" +
            encodeURIComponent("学员作品") +
            "&major=" +
            encodeURIComponent("前端云计算")
        )
        .then((res) => {
          this.projectlist = res.data.data; //学员作品列表数据
        });
    },
    getmajorvideo() {
      axios
        .get(
          "/api/block/getItemsByName?name=" +
            encodeURIComponent("专业宣传") +
            "&major=" +
            encodeURIComponent("全栈开发")
        )
        .then((res) => {
          this.majorvideo = res.data.data; //学员感言列表数据
        });
    },
    //获取专业教师
    getteacherlist() {
      axios
        .get(
          "/api/block/getItemsByName?name=" +
            encodeURIComponent("教师团队") +
            "&major=" +
            encodeURIComponent("前端云计算")
        )
        .then((res) => {
          this.teacherlist = res.data.data; //教师团队列表数据
        });
    },
    checkVideoFun(src) {
      this.videoState = true;
      this.videoSrc = src;
    },
    checkimgFun(src) {
      this.imgState = true;
      this.imgSrc = src;
    },
    masksCloseFun() {
      this.videoState = false;
      this.imgState = false;
    },
  },
  components: {
    bottom_public,
    header_public,
    recollections_publc,
  },
  mounted() {
    // 骨干教师
    this.swiperTracher = new Swiper(".teacher-con", {
      slidesPerView: 4,
      slidesPerGroup: 4,
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: false, //修改swiper的父元素时，自动初始化swiper
      autoplay: {
        delay: 2000,
        disableOnInteraction: false, // 取消鼠标操作后的轮播暂停【无操作轮播继续】【参考链接1】
        stopOnLastSlide: false, // 在最后一页停止
      },
      pagination: {
        el: ".teach-spit",
        clickable: true,
      },
      navigation: {
        nextEl: ".teachers-switch-next",
        prevEl: ".teachers-switch-prev",
      },
    });
  },
};
</script>
<style scoped>
.garybg >>> .con_title span,
.garybg >>> .con_title h4 {
  color: #ff1d155c !important;
}
</style>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.cloudtop {
  width: 100%;
  height: 60vw;
  background: url("~@/assets/images/cloudtop.png") no-repeat;
  background-size: 100% 100%;
}

.cloudtopword {
  width: 62.5vw;
  height: 20vw;
  margin: 0 auto;
  text-align: left;
  color: #fff;
}

.cloudp1 {
  width: 100%;
  height: 10vw;
  font-size: 8.75rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: rgba(247, 241, 245, 1);
  padding-top: 14vw;
}

.cloudp2 {
  font-size: 1.875rem;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: rgba(247, 241, 245, 1);
  margin-top: 2vw;
  margin-bottom: 2vw;
}

//为什么首选前端云计算
.garybg {
  width: 100%;
  height: 67.18vw;
  background: url("~@/assets/images/garybg.png") no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}

.p1 {
  // width: 20vw;
  height: 1.42vw;
  font-size: 2.75rem;
  color: #000443;
  font-family: PingFang;
  font-weight: 500;
  line-height: 1.17vw;
  margin: 0 auto;
  padding-top: 10vw;
}

.study {
  width: 62.5vw;
  height: 15vw;
  margin: 0 auto;
  margin-top: 5vw;
  display: flex;
  justify-content: space-between;
}

.study dl {
  width: 33%;
  height: 100%;
}

.study dl .dd1 {
  width: 100%;
  height: 1.19vw;
  font-size: 1.5rem;
  font-family: PingFang;
  font-weight: 500;
  color: #000443;
  line-height: 1.19px;
  margin-top: 2vw;
  text-align: center;
}

.study dd {
  width: 100%;
  height: 1vw;
  font-size: 1.2rem;
  font-family: PingFang;
  font-weight: 500;
  color: #7a8091;
  line-height: 1.3vw;
  margin-bottom: 0.5vw;
  margin-top: 0.5vw;
  text-align: center;
}

//2020V5.0课程
//分段课程体系
.course {
  width: 62.5vw;
  margin: 0 auto;
  margin-top: 5vw;
  display: flex;
  justify-content: space-around;
}

.courseword {
  width: 30vw;
  font-size: 1.25rem;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: rgba(126, 123, 144, 1);
  margin-top: 10vw;
}

.coursep {
  font-size: 2.75rem;
  font-family: PingFang;
  font-weight: 500;
  color: rgba(0, 11, 56, 1);
  text-align: left;
}

.courseword ul {
  font-size: 1.25rem;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: rgba(126, 123, 144, 1);
  line-height: 44px;
  text-align: left;
  margin-top: 2vw;
}

//核心知识
.center {
  width: 62.5vw;
  margin: 0 auto;
  margin-top: 8vw;
  display: flex;
  justify-content: space-between;
}

.center ul {
  width: 32%;
  height: 13vw;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 7px 18px 0px rgba(206, 212, 239, 0.43);
  border-radius: 2px;
}
.center ul:hover {
  transform: translateY(-10px);
  transition: all 0.5s;
}
.center ul .li1 {
  font-size: 1.5rem;
  font-family: PingFang;
  font-weight: bold;
  color: rgba(8, 15, 77, 1);
  text-align: left;
  margin-left: 2vw;
  margin-top: 2vw;
}
.center ul .li2 {
  width: 85%;
  font-size: 1.5rem;
  font-family: PingFang;
  font-weight: bold;
  color: rgba(8, 15, 77, 1);
  text-align: left;
  margin-left: 2vw;
  // margin: 0 auto;
  margin-top: 1vw;
  line-height: 1.5vw;
}

// 前景介绍
.pp {
  font-size: 1.3rem;
  font-family: PingFang;
  font-weight: 500;
  color: rgba(114, 114, 114, 1);
  margin: 0 auto;
  margin-top: 5vw;
}

.p2 {
  width: 40vw;
  height: 2vw;
  font-size: 2.75rem;
  font-family: PingFang;
  font-weight: 500;
  color: #1c1a5b;
  line-height: 1vw;
  margin: 0 auto;
  margin-top: 2vw;
}

.p3 {
  width: 42.2vw;
  height: 2.86vw;
  font-size: 1.125rem;
  font-family: PingFang;
  font-weight: 500;
  color: rgba(135, 138, 156, 1);
  line-height: 1.61vw;
  margin: 0 auto;
  margin-top: 2vw;
  margin-bottom: 2vw;
}

.futurevideo {
  width: 62.5vw;
  height: 32.39vw;
  // margin-left: 14vw;
  margin: 0 auto;
  margin-top: 5vw;
  // background-color: aqua;
  position: relative;
}

.videoimg {
  width: 45vw;
  height: 28.69vw;
}

.ladyimg {
  width: 29vw;
  height: 17.3vw;
  position: absolute;
  right: 10vw;
  bottom: -2vw;
}

//课程体系优势
.advantage {
  width: 62.5vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.advantagebox {
  width: 39vw;
  height: 5.5vw;
  display: flex;
  justify-content: space-between;
  // background-color: rgb(196, 45, 45);
  margin-top: 0.5vw;
}

.advantageflex {
  display: flex;
  justify-content: space-between;
  margin-top: 5vw;
  // background-color: #1d155c
}

.advantageflex img {
  width: 2.2vw;
  height: 2.2vw;
}

.advantage ul {
  width: 18.5vw;
  height: 4.68vw;
  text-align: left;
  // margin-top: 1vw;
  // background-color: #f47920;
}

.advantage ul .li1 {
  font-size: 1.5rem;
  font-family: PingFang;
  font-weight: bold;
  color: rgba(11, 14, 59, 1);
}

.advantage ul .li2 {
  font-size: 1.2rem;
  font-family: PingFang;
  font-weight: 500;
  color: rgba(135, 138, 156, 1);
}

//教师团队轮播
.swiper_con {
  position: relative;
}

.con_style {
  width: 62.5vw !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.teacher-con {
  height: 19.8vw !important;
}

.teachers_box {
  width: 15.625vw !important;
  height: 15.625vw !important;
  position: relative;
  border-radius: 5px;
  margin-right: 0 !important;
}

.teachers_box a {
  display: inline-block;
  width: 92% !important;
  height: 100% !important;
  overflow: hidden;
}

.teachers_box img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  transition: all 1s; /*图片放大过程的时间*/
  position: relative;
}

.teachers_box img:hover {
  transform: scale(1.1); /*以y轴为中心旋转*/
}

.teacher_team_con {
  width: 92%;
  height: 16%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  padding: 0 14px;
  margin: auto;
  background-color: #000;
  opacity: 0.5;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}

.team_con_bottom p {
  font-size: 1.35rem;
  font-weight: bold;
  color: #ffffff;
}

.team_con_bottom span {
  font-size: 0.875rem;
  font-weight: bold;
  color: #ffffff;
  line-height: 1.8vw;
}

.text_left {
  text-align: justify !important;
}

.text_family {
  font-family: PingFang;
}

.major-spit {
  --swiper-theme-color: #cacaca;
  --swiper-pagination-color: #e11e2c; /* 两种都可以 */
}

.teachers-switch-next,
.teachers-switch-prev {
  border-radius: 50%;
  width: 2.604vw;
  height: 2.604vw;
  background-color: #000000;
  opacity: 1 !important;
}

.teachers-switch-next,
.teachers-switch-prev {
  font-size: 1.125rem !important;
  outline: none;
  top: 36% !important;
}

.teachers-switch-prev {
  left: -4.25rem !important;
}

.teachers-switch-next {
  right: -4.25rem !important;
}

.teachers-switch-next:after,
.teachers-switch-prev:after {
  font-size: 1.125rem;
}

/////
.news {
  width: 62.5vw;
  height: 4.6vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.news p {
  text-align: left;
  margin-bottom: 0.5vw;
}

.newsp1 {
  font-size: 0.81rem;
  font-family: PingFang;
  font-weight: bold;
  color: rgba(109, 109, 109, 1);
}

.newsp2 {
  font-size: 2.25rem;
  font-family: PingFang;
  font-weight: 800;
  color: rgba(28, 26, 91, 1);
}

.update {
  width: 62.5vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 1vw;
}

.update p {
  width: 31%;
  font-family: PingFang;
  text-align: left;
}

.update1 {
  width: 62.5vw;
  margin: 0 auto;
  display: flex;
  // justify-content: space-between;
  margin-top: 1vw;
}

.update1 .update1p {
  display: inline-block;
  width: 2.8vw;
  height: 2.8vw;
  border-radius: 5px;
  background: linear-gradient(to right, #f47920 0%, #faa755 100%);
  font-size: 1rem;
  font-family: PingFang;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}

.update1 p {
  width: 78%;
  font-family: PingFang;
  text-align: left;
  font-size: 1.25rem;
  font-family: PingFang;
  font-weight: 800;
  color: rgba(28, 26, 91, 1);
}

.workflex {
  width: 62.5vw;
  margin: 0 auto;
  margin-top: 3vw;
  margin-bottom: 3vw;
  display: flex;
  justify-content: space-between;
}

.workflex img {
  width: 18vw;
  height: 16vw;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 12px 26px 1px rgba(206, 212, 239, 0.63);
  border-radius: 36px 0px 36px 0px;
}

.direction {
  width: 62.5vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.direction .li1 {
  font-size: 1.375rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(28, 26, 91, 1);
}

.direction .li2 {
  font-size: 1.2rem;
  font-family: PingFang;
  font-weight: bold;
  color: rgba(109, 109, 109, 1);
  margin-top: 1vw;
}

.job {
  width: 62.5vw;
  margin: 0 auto;
  margin-top: 5vw;
  display: flex;
  justify-content: space-between;
  padding-top: 4vw;
}

.jobword {
  margin-top: 4vw;
}

.job p {
  text-align: left;
  // margin-top: 5vw;
}

.job .img1 {
  width: 30vw;
  height: 30vw;
}

.job ul {
  width: 31vw;
  margin-top: 2.5vw;
  // list-style-type: square;
  font-family: PingFang;
  font-weight: 500;
  color: #707281;
  line-height: 1.5vw;
  text-align: left;
  font-size: 1.373rem;
}

.job li {
  margin-top: 1vw;
  // color: #14134F;
  line-height: 23px;
}

.workp2 {
  font-size: 2.75rem;
  font-family: PingFang;
  font-weight: 500;
  color: #1c1a5b;
  text-align: center;
}

.maska {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: #000000;
  opacity: 0.6;
}

.videomasks {
  max-width: 62vw;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 20;
  transform: translate(-50%, -50%);
}

.videomasks video {
  width: 100%;
  height: 100%;
}

.imagemasks {
  max-width: 1000px;
  position: fixed;
  left: 50%;
  top: 58%;
  overflow: auto;
  height: 550px;
  z-index: 20;
  transform: translate(-50%, -50%);
}

.imagemasks::-webkit-scrollbar {
  display: none;
}

.imagemasks img {
  width: 100%;
}
</style>
